@import "~admin-lte/bower_components/font-awesome/css/font-awesome.min.css";
@import "~admin-lte/bower_components/Ionicons/css/ionicons.min.css";
@import "~admin-lte/bower_components/bootstrap/dist/css/bootstrap.min.css";
//@import "~admin-lte/bower_components/bootstrap/dist/css/bootstrap-theme.min.css";
@import "~select2/dist/css/select2.min.css";
@import "~bootstrap-table/dist/bootstrap-table.min.css";
@import "~jquery-toast-plugin/dist/jquery.toast.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "~admin-lte/dist/css/AdminLTE.min.css";
@import "~admin-lte/dist/css/skins/skin-blue.min.css";
@import "~bootstrap-toggle/css/bootstrap-toggle.min.css";
@import "~admin-lte/plugins/iCheck/all.css";
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~chart.js/dist/Chart.min.css";
@import "../css/gantt";
@import "../css/radios-to-slider";
@import "../css/vars";
@import "~bootstrap-daterangepicker/daterangepicker.css";

.skin-blue {
    .main-header {
        z-index: 10;

        .navbar, li.user-header {
            background-color: $color-blue-light;

            .sidebar-toggle:hover {
                background-color: $color-blue-darken;
            }
        }

        .logo {
            background-color: $color-success;
        }

        .logo:hover {
            background-color: $color-blue-darken
        }
    }

    .main-sidebar, .control-sidebar {
        z-index: 9;
    }

    .control-sidebar-bg {
        z-index: 2;
    }

    .sidebar-menu>li.active>a {
        border-left-color: $color-blue;
    }

    .bg-light-blue {
        background-color: $color-blue-light !important;
    }

    .callout.callout-success {
        border-color: $color-blue-darken;
    }

    .btn-danger, .bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body
    {
        background-color: $color-danger !important;
    }

    .box.box-danger, .nav-tabs-custom.tab-danger>.nav-tabs>li.active {
        border-top-color: $color-danger !important;
    }

    .btn-danger, .callout.callout-danger {
        border-color: $color-orange-darken;
    }

    .notifications-menu {
        .dropdown-menu {
            width: auto;
        }
    }
}

.carre {
    display: inline-block;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.btn-success, .bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body,.pagination>.active>a {
    background-color: $color-success !important;
}

.badge.bg-danger {
    background-color: #a90404 !important;
}

.badge.bg-blue-dark {
    background-color:darken($color-blue, 20) !important;
}

.login-logo {
    img {
        margin: auto;
    }
}

.login-box-body {
    .checkbox input[type=checkbox] {
        position: static;
        margin-left: 0;
    }
}

.invalid-feedback {
    display: none;
}

.is-invalid
{
    border: 1px solid #a94442;
}

.is-valid
{
    border: 1px solid $color-blue-darken;
}


table {
    background-color: #fff;
}

.box {
    .form-check {
        margin-top: 2em;
    }
}

.slow .toggle-group { transition: left 0.7s; -webkit-transition: left 0.7s; }
.fast .toggle-group { transition: left 0.1s; -webkit-transition: left 0.1s; }
.quick .toggle-group { transition: none; -webkit-transition: none; }

.main-footer {
    .social-networks {
        margin-bottom: 0;

        li {
            display: inline-block;
            width: 22px;
            padding-bottom: 22px;
            background-size: 100% auto;
            background-position: left bottom;
            position: relative;

            a {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 2;
            }
        }

        .twitter {
            background-image: url('/img/social_networks/twitter-light.png');
        }

        .facebook {
            background-image: url('/img/social_networks/facebook.png');
        }

        .dribbble {
            background-image: url('/img/social_networks/deezer.png');
        }

        .github {
            background-image: url('/img/social_networks/github.png');
        }

        .pinterest {
            background-image: url('/img/social_networks/pinterest.png');
        }

        .linkedin {
            background-image: url('/img/social_networks/linkedin.png');
        }
    }
}

.sidebar-menu {
    .pull-right-container {
        width: 1.2em;
    }
}

// Block au dessus des tableaux
.block-top-list {
    background-color: #fff;
    border-bottom: 2px solid #222d32;
    padding: .5em;
    height: 50px;
}

.sub-block-top-list {
    background-color: #d8dadc;
    margin-bottom: 1em;
    padding: 1em;
}

@media (max-width: 800px) {
    .pull-right {
        float: none !important;
    }
}

.bootstrap-table {
    .pagination-detail {
        display: inline-block;
    }

    .pagination {
        float: right;
    }
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item:first-child {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.mt-0 {
    .form-check {
        margin-top: 0 !important;
    }
}

.padding-xs { padding: .25em; }
.padding-sm { padding: .5em; }
.padding-md { padding: 1em; }
.padding-lg { padding: 1.5em; }
.padding-xl { padding: 3em; }

.modal-header-danger {
    color:#fff;
    padding:9px 15px;
    border-bottom:1px solid #eee;
    background-color: #d9534f;
}

.modal-header-warning {
    color:#fff;
    padding:9px 15px;
    border-bottom:1px solid #eee;
    background-color: #f39c12;
}

.modal-body .datepicker {
    z-index: 10000 !important;
}

.bloc-file {
    text-align: center;
    border: 1px solid;
    width: 4em;
    padding: 0.5em 0 0.1em;
    display: inline-block;
    vertical-align: middle;
    margin: 1em;
}

.iframeElFinder {
    border: none;
    height: 100vh;
    width: 97%;
    margin: 0 1em;
}

li {
    label.form-check-label {
        font-weight: normal;
    }
}

.input-complet {
    background-color: #e5ccff;
}

.input-selectpicker-complet {
    .dropdown-toggle {
        background-color: #e5ccff;
    }
}

.page-password-forgot {
    .top_title {
        padding: 1em;
        background-color: $color-blue;
        color: #fff;
        font-weight: bold;
        font-size: 1.3em;
    }

    .center-block {
        margin-top: 2em;
        font-size: 1.1em;
    }
}

form .is-invalid.form-control, form .has-error .form-control, form .bootstrap-select.is-invalid .dropdown-toggle {
    border-color: red !important;
    border-width: 2px !important;
}
/*************************/
/***** NOTIFICATIONS *****/
/*************************/
.notifications-menu
{
    .menu {
        li {
            cursor: pointer;
        }
    }

    .icon::before {
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }

    .type_1::before {
        color: #00c0ef;
        content: "\f7e6";
    }

    .type_2::before {
        color: #f9651b;
        content: "\f573";
    }

    .type_3::before {
        color: #f39c12;
        content: "\f1b9";
    }

    .type_4::before {
        color: #8d4cd0;
        content: "\f553";
    }

    .type_5::before {
        color: #f39c12;
        content: "\f552";
    }

    .type_6::before {
        color: #f93737;
        content: "\f571";
    }

    .type_7::before {
        color: #f9651b;
        content: "\f0b1";
    }

    .type_9::before {
        color: #58c423;
        content: "\f153";
    }
}

.marginT2 {
    margin-top: 1%;
}

.cke_notifications_area {
    display: none !important;
}