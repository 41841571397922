.gantt, .gantt2 {
    width: 100%;
    margin: 20px auto;
    //border: 14px solid #ddd;
    //-webkit-border-radius: 6px;
    //-moz-border-radius: 6px;
    //border-radius: 6px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.gantt:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.fn-gantt {
    width: 100%;
}

.fn-gantt .fn-content {
    overflow: hidden;
    position: relative;
    width: 100%;
}




/* === LEFT PANEL === */

.fn-gantt .leftPanel {
    float: left;
    width: 411px;
    overflow: hidden;
    border-right: 1px solid #DDD;
    position: relative;
    z-index: 20;
    box-sizing: initial !important;
    -webkit-box-sizing: initial !important;
}

.fn-gantt .row {
    float: left;
    height: 24px;
    line-height: 24px;
    margin-left: -24px;
}

.fn-gantt .leftPanel .fn-label {
    display: inline-block;
    margin: 0 0 0 5px;
    color: #484A4D;
    width: 132px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fn-gantt .leftPanel .row0 {
    border-top: 1px solid #DDD;
}
.fn-gantt .leftPanel .name, .fn-gantt .leftPanel .desc, .fn-gantt .leftPanel .site, .fn-gantt .leftPanel .customer {
    float: left;
    height: 24px;
    margin: 0;
    border-bottom: 1px solid #DDD;
    background-color: #f6f6f6;
}

.fn-gantt .leftPanel .title {
    float: left;
    height: 23px;
    margin: 0;
    background-color: #f6f6f6;
}

.fn-gantt .leftPanel .name {
    width: 115px;
    font-weight: bold;
}

.fn-gantt .leftPanel .desc, .fn-gantt .leftPanel .site, .fn-gantt .leftPanel .customer {
    width: 148px;
}

.fn-gantt .leftPanel .fn-wide, .fn-gantt .leftPanel .fn-wide .fn-label {
    width: 225px;
}

.fn-gantt .spacer {
    margin: -2px 0 1px 0;
    border-bottom: none;
    background-color: #f6f6f6;
    padding-top: 1em;
    font-weight: bold;
}

/* === RIGHT PANEL === */

.fn-gantt .rightPanel {
    overflow: hidden;
}

.fn-gantt .dataPanel {
    margin-left: 0px;
    border-right: 1px solid #DDD;
    background-image: url(../../public/img/grid.png);
    background-repeat: repeat;
    background-position: 24px 23px;
}
.fn-gantt .day, .fn-gantt .date {
    overflow: visible;
    width: 24.96px;
    line-height: 24px;
    text-align: center;
    border-left: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    margin: -1px 0 0 -1px;
    font-size: 11px;
    color: #484a4d;
    text-shadow: 0 1px 0 rgba(255,255,255,0.75);
    text-align: center;
}

.fn-gantt .holiday {
    background-color: #ffd263;
    height: 23px;
    margin: 0 0 -1px -1px;
}

.fn-gantt .today {
    background-color: #fff8da;
    height: 23px;
    margin: 0 0 -1px -1px;
    font-weight: bold;
    text-align: center;
}

.fn-gantt .sa, .fn-gantt .sn, .fn-gantt .wd {
    height: 23px;
    margin: 0 0 0 -0.91px;
    text-align: center;
}

.fn-gantt .sa, .fn-gantt .sn {
    color: #939496;
    background-color: #f5f5f5;
    text-align: center;
}

.fn-gantt .wd {
    background-color: #f6f6f6;
    text-align: center;
}

.fn-gantt .rightPanel .month, .fn-gantt .rightPanel .year {
    float: left;
    overflow: hidden;
    border-left: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
    height: 23px;
    margin: 0 0 0 -1px;
    background-color: #f6f6f6;
    font-weight: bold;
    font-size: 11px;
    color: #484a4d;
    text-shadow: 0 1px 0 rgba(255,255,255,0.75);
    text-align: center;
}

.fn-gantt-hint {
    border: 5px solid #edc332;
    background-color: #fff5d4;
    padding: 10px;
    position: absolute;
    display: none;
    z-index: 11;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.fn-gantt .bar {
    background-color: #D0E4FD;
    height: 18px;
    margin: 4px 3px 3px 3px;
    position: absolute;
    z-index: 10;
    text-align: center;
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.25) inset;
    -moz-box-shadow: 0 0 1px rgba(0,0,0,0.25) inset;
    box-shadow: 0 0 1px rgba(0,0,0,0.25) inset;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.fn-gantt .bar .fn-label {
    line-height: 18px;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-shadow: 0 1px 0 rgba(255,255,255,0.4);
    color: #414B57 !important;
    text-align: center;
    font-size: 11px;
}

.fn-gantt .ganttBlue {
    background-color: #D0E4FD;
}
.fn-gantt .ganttBlue .fn-label {
    color: #617ffd !important;
}

.fn-gantt .ganttPink {
    background-color: #F9C4E1;
}
.fn-gantt .ganttPink .fn-label {
    color: #78436D !important;
}

.fn-gantt .ganttRed {
    background-color: #ee5656;
}
.fn-gantt .ganttRed .fn-label {
    color: #852121 !important;
}

.fn-gantt .ganttGreen {
    background-color: #D8EDA3;
}
.fn-gantt .ganttGreen .fn-label {
    color: #778461 !important;
}

.fn-gantt .ganttOrange {
    background-color: #FCD29A;
}
.fn-gantt .ganttOrange .fn-label {
    color: #714715 !important;
}

.fn-gantt .ganttYellow {
    background-color: #EFFC86;
}
.fn-gantt .ganttYellow .fn-label {
    color: #716B14 !important;
}

.fn-gantt .ganttViolet {
    background-color: #e1abfc;
}
.fn-gantt .ganttViolet .fn-label {
    color: #4f2871 !important;
}

.fn-gantt .ganttGray {
    background-color: #c6bfbe;
}
.fn-gantt .ganttGray .fn-label {
    color: #71696d !important;
}

.fn-gantt .ganttGrayDark {
    background-color: #a7a09f;
}
.fn-gantt .ganttGrayDark .fn-label {
    color: #574f53 !important;
}

.fn-gantt .ganttTurquoise {
    background-color: #17b791;
}

.fn-gantt .ganttTurquoise .fn-label {
    color: #034b3d !important;
}

/* === BOTTOM NAVIGATION === */

.fn-gantt .bottom {
    clear: both;
    background-color: #f6f6f6;
    width: 100%;
}
.fn-gantt .navigate {
    border-top: 1px solid #DDD;
    padding: 10px 0 10px 225px;
}

.fn-gantt .navigate .nav-slider {
    height: 20px;
    display: inline-block;
}

.fn-gantt .navigate .nav-slider-left, .fn-gantt .navigate .nav-slider-right {
    text-align: center;
    height: 20px;
    display: inline-block;
}

.fn-gantt .navigate .nav-slider-left {
    float: left;
}

.fn-gantt .navigate .nav-slider-right {
    float: right;
}

.fn-gantt .navigate .nav-slider-content {
    text-align: left;
    width: 160px;
    height: 20px;
    display: inline-block;
    margin: 0 10px;
}

.fn-gantt .navigate .nav-slider-bar, .fn-gantt .navigate .nav-slider-button {
    position: absolute;
    display: block;
}

.fn-gantt .navigate .nav-slider-bar {
    width: 155px;
    height: 6px;
    background-color: #838688;
    margin: 8px 0 0 0;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset;
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset;
    box-shadow: 0 1px 3px rgba(0,0,0,0.6) inset;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.fn-gantt .navigate .nav-slider-button {
    width: 17px;
    height: 60px;
    background: url(../../public/img/slider_handle.png) center center no-repeat;
    left: 0px;
    top: 0px;
    margin: -26px 0 0 0;
    cursor: pointer;
}

.fn-gantt .navigate .page-number {
    display: inline-block;
    font-size: 10px;
    height: 20px;
}

.fn-gantt .navigate .page-number span {
    color: #666666;
    margin: 0 6px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
}

.fn-gantt .navigate a:link, .fn-gantt .navigate a:visited, .fn-gantt .navigate a:active {
    text-decoration: none;
}

.fn-gantt .nav-link {
    margin: 0 3px 0 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 0px;
    background: #595959 url(../../public/img/icon_sprite.png) !important;
    border: 1px solid #454546;
    cursor: pointer;
    vertical-align: top;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.1) inset, 0 1px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 1px 0 rgba(255,255,255,0.1) inset, 0 1px 1px rgba(0,0,0,0.2);
    box-shadow: 0 1px 0 rgba(255,255,255,0.1) inset, 0 1px 1px rgba(0,0,0,0.2);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.fn-gantt .nav-link:active {
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.25) inset, 0 1px 0 #FFF;
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.25) inset, 0 1px 0 #FFF;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25) inset, 0 1px 0 #FFF;
}

.fn-gantt .navigate .nav-page-back {
    background-position: 1px 0 !important;
    margin: 0;
}

.fn-gantt .navigate .nav-page-next {
    background-position: 1px -16px !important;
    margin-right: 15px;
}

.fn-gantt .navigate .nav-slider .nav-page-next {
    margin-right: 5px;
}

.fn-gantt .navigate .nav-begin {
    background-position: 1px -112px !important;
}

.fn-gantt .navigate .nav-prev-week {
    background-position: 1px -128px !important;
}

.fn-gantt .navigate .nav-prev-day {
    background-position: 1px -48px !important;
}

.fn-gantt .navigate .nav-next-day {
    background-position: 1px -64px !important;
}

.fn-gantt .navigate .nav-next-week {
    background-position: 1px -160px !important;
}

.fn-gantt .navigate .nav-end {
    background-position: 1px -144px !important;
}

.fn-gantt .navigate .nav-zoomOut {
    background-position: 1px -96px !important;
}

.fn-gantt .navigate .nav-zoomIn {
    background-position: 1px -80px !important;
    margin-left: 15px;
}

.fn-gantt .navigate .nav-now {
    background-position: 1px -32px !important;
}

.fn-gantt .navigate .nav-slider .nav-now {
    margin-right: 5px;
}

.fn-gantt-loader {
    //background-image: url(../img/loader_bg.png);
    z-index: 30;
}

.fn-gantt-loader-spinner {
    width: 100px;
    height: 20px;
    position: absolute;
    margin-left: 50%;
    margin-top: 50%;
    text-align: center;
}
.fn-gantt-loader-spinner span {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.row:after {
    clear: both;
}
